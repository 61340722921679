<template>
  <div class="addresslist">
    <van-nav-bar title="收货地址" left-arrow @click-left="onClickLeft" />
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <van-address-list
        v-model="chosenAddressId"
        :list="list"
        default-tag-text="默认"
        :add-button-text="bottomBtnTxt"
        @select="selectAddress"
        @add="onAdd"
        @edit="onEdit"
      />
    </van-list>
    
    
  </div>
</template>
<script>
export default {
  name: "AddressList",
  data() {
    return {
      screenWidth: document.body.clientWidth, // 屏幕宽
      screeHeight: document.body.clientHeight, // 屏幕高
      chosenAddressId: "",
      list: [],
      currSize: 20,
      currOffset: 0,
      type: "", // 是否是订单确认页跳转过来的
      bottomBtnTxt: "新增地址",
      shareid: 0,
      merchid: 0,
      loading: false,
      finished: false,
    };
  },
  mounted() {
    this.goodsId = this.$route.query.id;
    this.type = this.$route.query.type;
    
    if (this.$route.query.shareid) {
      this.shareid = this.$route.query.shareid
    }else if (localStorage.getItem("DK_MEMBERID")) {
      this.shareid = localStorage.getItem("DK_MEMBERID");
    }
    this.merchid = this.$route.query.merchid ? this.$route.query.merchid : 0;
    this.$parent.getmerchname(this.merchid, "收货地址");
  },
  methods: {
    onLoad() {
      let _that = null;
      _that = this;
      const uid = localStorage.getItem('DK_UID') ? localStorage.getItem('DK_UID') : "";
      _that.$axios.defaults.headers.common['Authorization'] = uid;
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios.post(
        _that.$store.state.domain + 'web/address/get_list',
        _that.$qs.stringify({
          size: _that.currSize,
          offset: _that.currOffset
        })
      ).then(response => {
        console.log(response);
        _that.loading = false;
        if (response && response.data) {
          if (_that.currOffset == 0) {
            _that.list = response.data
          }else {
            response.data.forEach(item => {
              _that.list.push(item);
            })
          }
          if (response.data.length >= _that.currSize) {
            _that.currOffset += _that.currSize
            _that.finished = false;
          }else {
            _that.currOffset += response.data.length
            _that.finished = true;
          }
        }else {
          _that.finished = true;
        }
      }).catch(error => {
        console.log(error);
      })
    },
    // 返回上一步
    onClickLeft() {
      this.$router.go(-1);
    },
    // 添加收货地址
    onAdd() {
      console.log("添加收货地址");
      this.$router.push({ name: "AddressEdit", query: {title: '添加地址', shareid: this.shareid, merchid: this.merchid } });
    },
    // 修改收货地址
    onEdit(item, index) {
      console.log("修改收货地址",item);
      this.$router.push({ name: "AddressEdit", query: { title: '修改地址', id: item.id, shareid: this.shareid, merchid: this.merchid } });
    },
    // 选择收货地址
    selectAddress(item, index){
      let order = null;
      order = localStorage.getItem("DK_DATA");
      if(this.type == 'activity') {
        localStorage.setItem("address",JSON.stringify(item));
        this.$router.go(-1);
      }else if (order) {
        localStorage.setItem("DK_ADDRESS",JSON.stringify(item));
        this.$router.go(-1);
      }
      
    }
  }
};
</script>
<style lang="less">
.addresslist{
  min-height: 100%;
  width: 100%;
  position: relative;
  z-index: 2;
  background: #ededed;
  .van-nav-bar{
    .van-nav-bar__left{
      .van-icon{
        color: #333;
      }
    }
  }
  .van-address-item__edit{
    padding: 20px;
    right: 0;
  }
  .van-list {
    padding-bottom: 60px;
    .van-address-list {
      padding-bottom: 10px;
    }
  }
}
</style>
